@import "../../common.less";
.mainPage{
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
}
.container{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  transform: translate(-50%,-50%);
}
.indexPageImg{
  width: 50%;
}
.textBox{
  float: right;
  width: 50%;
  text-align: center;
}
.title{
  font-size: 32px;
  font-weight: bold;
  margin-top: 20%;
}
.noteText{
  margin-top: 3%;
  font-size: 24px;
  color: #999;
}