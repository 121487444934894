:global .ant-tabs {
  height: 100%;
}
:global .ant-tabs-content {
  height: 100%;
}
:global .ant-tabs-tabpane {
  height: 100%;
}
.maintheme {
  position: absolute;
  min-height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
}
.button {
  width: 144px;
  font-size: 16px;
  border-radius: 4px;
  height: 34px;
  border: none;
  cursor: pointer;
}
.buttonrestvalue {
  font-size: 14px;
  width: 80px;
  letter-spacing: 0;
  border-radius: 4px;
}
.buttongetvalue {
  font-size: 14px;
  color: #1585ff;
  background: #fff;
  width: 80px;
  border-radius: 4px;
}
.noSelect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
}
.btnText {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
}
.noText {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  width: 24px;
}
.hiddenBtn {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  visibility: hidden;
}
.btnDetail {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  color: #67cfff;
}
.btnWarn {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  color: darkorange;
}
.btnAdd {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  color: limegreen;
}
.btnEdit {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  color: #0178ee;
}
.btnDelete {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  color: red;
}
.containerTitle {
  font-size: 18px;
  text-indent: 20px;
  margin-bottom: 10px;
  color: #29A6FF;
  letter-spacing: 1px;
}
.containerTitle:before {
  content: " ";
  display: inline-block;
  width: 30px;
  height: 30px;
  background-image: url("../../images/cloud.png");
  background-size: 100% 100%;
  margin-right: 5px;
}
.containerTitle:after {
  content: " ";
  display: block;
  width: 100%;
  height: 2px;
  background: linear-gradient(0deg, #29a6ff 0%, #ffffff 100%);
  background: -moz-linear-gradient(left, #29A6FF, #fff);
  background: -o-linear-gradient(left, #29A6FF, #fff);
  background: -webkit-gradient(linear, 0% 100%, 100% 0%, from(#29A6FF), to(#fff));
}
.returnPage {
  float: left;
  clear: both;
  font-size: 16px;
  padding-left: 30px;
  color: #333;
  cursor: pointer;
  margin: 5px 40px;
}
.returnPage:before {
  content: "<< ";
  display: inline;
}
.returnPage:hover {
  color: #3299ff;
  padding-left: 0;
}
.returnPage:hover:before {
  content: "<<<<< ";
  display: inline;
}
.nextPage {
  float: right;
  clear: both;
  font-size: 16px;
  padding-right: 30px;
  color: #333;
  margin: 5px 40px;
  cursor: pointer;
}
.nextPage:after {
  content: " >>";
  display: inline;
}
.nextPage:hover {
  color: #3299ff;
  padding-right: 0;
}
.nextPage:hover:after {
  content: " >>>>>";
  display: inline;
}
.toolBtn {
  min-width: 120px;
  margin: 20px 10px;
}
.jumpTitle:after {
  content: ' ';
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url("../../images/outIcon01.png");
  background-size: 100% 100%;
  vertical-align: text-bottom;
  margin-left: 5px;
}
.jumpText {
  cursor: pointer;
}
.jumpText:hover {
  color: #29A6FF;
}
.stringType {
  color: green;
}
.numberType {
  color: darkorange;
}
.booleanType {
  color: blue;
}
.nullType {
  color: magenta;
}
.keyType {
  color: red;
}
.main {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-size: 100% 100%;
}
.loading {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
}
.loading_img {
  width: 50px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  -moz-animation: spin 2s linear infinite;
  -o-animation: spin 2s linear infinite;
}
@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}
@-moz-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}
@-o-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}
.anim {
  position: absolute;
  width: 100%;
  height: 100%;
}
.big_box {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0px;
  left: 0;
  right: 0;
  width: 500px;
  height: 550px;
  text-align: center;
  padding-top: 60px;
  background-color: rgba(250, 255, 255, 0.9);
}
.title {
  margin-bottom: 62px ;
  font-size: 30px;
  font-weight: bold;
  color: #666;
  letter-spacing: 2px;
}
.box {
  width: 460px;
  height: 350px;
  min-height: 300px;
  margin: auto;
  box-sizing: border-box;
  padding: 0 30px;
}
.input input {
  background-color: #fff;
  font-size: 16px;
  color: #333;
  width: 350px;
  height: 40px;
  padding-left: 10px;
  padding-right: 40px;
}
.input::-webkit-input-placeholder {
  color: #aaa;
}
.input:-moz-placeholder {
  color: #aaa;
}
.input::-moz-placeholder {
  color: #aaa;
}
.input:-ms-input-placeholder {
  color: #aaa;
}
.input:-webkit-autofill {
  color: #aaa;
  caret-color: #aaa;
  border-radius: 2px;
}
.input1 input {
  font-size: 16px;
  background-color: #fff;
  color: #333;
  text-fill-color: #aaa;
  -webkit-text-fill-color: #aaa;
  width: 350px;
  height: 40px;
  padding-left: 10px;
  padding-right: 40px;
}
.input1:-webkit-autofill {
  color: #aaa;
  caret-color: #aaa;
  border-radius: 2px;
}
.input1::-webkit-input-placeholder {
  color: #aaa;
}
.input1:-moz-placeholder {
  color: #aaa;
}
.input1::-moz-placeholder {
  color: #aaa;
}
.input1:-ms-input-placeholder {
  color: #aaa;
}
.img1 {
  width: 24px;
  height: 24px;
  position: absolute;
  margin-left: -35px;
  margin-top: 13px;
}
.img2 {
  width: 24px;
  height: 24px;
  position: absolute;
  margin-left: -35px;
  margin-top: 33px;
}
.Spin {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.login {
  margin-top: 30px;
}
.h2 {
  margin-bottom: 35px;
  color: #666;
  font-size: 18px;
}
.btn {
  width: 400px;
  height: 40px;
  background-color: #1585ff;
  color: #fff;
  font-size: 16px;
  margin-top: 70px;
}
.btn:hover {
  background-color: #1585ff;
  color: #fff;
  font-size: 17px;
}
.img {
  position: absolute;
  width: 16px;
  left: 60px;
  height: 16px;
  margin-top: 18px;
}
.remember {
  position: absolute;
  font-size: 14px;
  margin-top: 15px;
  color: #1585ff;
  margin-left: 25px;
}
.error {
  position: absolute;
  font-size: 12px;
  margin-top: 17px;
  left: 50px;
  color: red;
}
.bottom {
  position: absolute;
  bottom: 20px;
  margin: auto;
  left: 0;
  right: 0;
}
.bottomText {
  font-size: 14px;
  color: #999;
  text-align: center;
}
.bottom1 {
  margin-top: 3px;
  font-size: 12px;
  color: #1585ff;
  opacity: 0.4;
  text-align: center;
}
.icon {
  width: 40px;
  font-size: 20px;
}
.block {
  height: 25px;
}
.forgetPassword {
  position: absolute;
  right: 50px;
  margin-top: 25px;
  font-size: 14px;
  color: #29A6FF;
  cursor: pointer;
}
.contactText {
  font-size: 16px;
}
