@import "../../common.less";
@topHeight: 80px;
@bottomHeight: 0;
.mainPage{
  box-sizing: border-box;
  overflow: hidden;
  word-break: break-all;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}
.topMsgBox{
}
.centerMsgBoxOuter{
  flex: 1;
  position: relative;
}
.centerMsgBox{
  position: absolute;
  top: 0;
  left: 0;
  right: 400px;
  bottom: 0;
  overflow: scroll;
}
.mobileViewBox{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 400px;
  border-radius: 50px;
  background: url("../../images/phone_modal.png");
  background-size: 100% 100%;
}
.mobileViewInner{
  position: absolute;
  top: 2.1vh;
  bottom: 2.6vh;
  right: 10px;
  left: 10px;
  //border: 4px solid red;
  border-radius: 40px;
}
.mobileHead{
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 40px;
  border-bottom: 2px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
}
.mobileFooter{
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 40px;
  border-top: 2px solid #000;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  //font-weight: bold;
  //font-size: 16px;
}
.mobileContentBox{
  position: absolute;
  top: 40px;
  bottom: 40px;
  right: 0;
  left: 0;
  overflow: auto;
}
.bottomMsgBox{
  position: absolute;
  height: @bottomHeight;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.editArea{
  border: 1px solid #959595;
  height: 100%;
  &>div:last-child{
    height: 100%;
  }
}
.editBar{
  background-color: rgba(73, 143, 255, 0.3);
}