.show {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
.btnBox {
  box-sizing: border-box;
  padding-top: 30px;
  text-align: right;
}
.btn {
  margin: 0 10px;
}
.redBtn {
  background-color: #e4393c !important;
  border-color: #e4393c !important;
  color: #fff !important;
}
.redBtn:hover {
  background-color: #e4393c;
  border-color: #e4393c;
  color: #fff;
}
.contentText {
  box-sizing: border-box;
  text-align: center;
  padding: 10px 20px;
  font-size: 16px;
}
.tipText {
  box-sizing: border-box;
  text-align: center;
  padding: 10px 20px;
  font-size: 14px;
  word-wrap: break-word;
  word-break: break-all;
}
.icon {
  display: inline-block;
  color: #e4393c;
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px;
}
.redText {
  color: #e4393c;
  padding: 0 10px;
  word-wrap: break-word;
  word-break: break-all;
}
