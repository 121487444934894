.upfileBox{
  box-sizing: border-box;
}
.delIcon{
  position: absolute;
  width: 15px;
  height: 15px;
  top: 5px;
  right: 5px;
  background-size: 100% 100%;
  opacity: 0.6;
  cursor: pointer;

  &:hover{
    opacity: 1;
  }
}
.imgStyle{
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  width: 75px;
  height: 75px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 4px;
  margin: 8px 8px 8px 0;
  overflow: hidden;
  cursor: pointer;
  text-align: center;
}
@bigImgHeight: 260px;
.imgStyleBig{
  .imgStyle;
  width: 100%;
  height: @bigImgHeight;
  border: 1px solid #2db7f5;
  background-color: #f5f5f5;
}
.videoBox{
  box-sizing: border-box;
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 10px auto;

  .videoStyle{
    width: 100%;
  }
  .delIconForVideo{
    .delIcon;
    width:30px;
    height: 30px;
  }
}
.loadingBox{
  .imgStyle;
  border: 1px dashed #dfdfdf;
  cursor: auto;
  line-height: 60px;
  font-size: 14px;
  background-size: 150% 150%;
  background-position: 50% 20%;
}
.loadingBoxBig{
  .loadingBox;
  width: 100%;
  height: @bigImgHeight;
  line-height: 150px;
  background-size: 100% 100%;
}
.addBtnBox{
  .imgStyle;
  border: 1px dashed #dfdfdf;
  .addInput{
    display: none !important;
  }
  .inputAdd{
    display: inline-block;
    width: 100%;
    height: 100%;
    //opacity: 0;
    cursor: pointer;
    z-index: 99;
    position: relative;
  }
  .addBtn{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);

    font-size: 20px;
    font-weight: bold;
    color: #bbbbbb;
  }
  &:hover{
    border: 1px dashed #4388f4;

    .addBtn{
      color: #4388f4;
    }
  }
}
.addBtnBoxBig{
  .addBtnBox;
  width: 100%;
  height: @bigImgHeight;
  background-color: #f5f5f5;
}