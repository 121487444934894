.container {
  width: 100%;
  margin-bottom: 15px;
  overflow: visible;
  position: relative;
}
.hidden {
  display: none;
}
.hiddenDiv {
  visibility: hidden;
  height: 0;
  overflow: hidden;
}
.show {
  padding-top: 25px;
  width: 100%;
  overflow: visible;
  position: relative;
}
.inputStyle {
  width: 30%;
  font-size: 16px;
}
.inputErr {
  width: 30%;
  font-size: 16px;
}
.inputErr input {
  border-color: #e4393c !important;
}
.btn {
  margin-left: 20px;
  width: 100px;
}
.searchBtn {
  margin-left: 20px;
  width: 100px;
}
.resetBtn {
  margin-left: 20px;
  width: 100px;
}
.extraSearchBtn {
  margin-left: 20px;
  width: 100px;
  width: 110px;
}
.clearBtn {
  cursor: pointer;
  color: #2db7f5;
}
