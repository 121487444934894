@import "../../../common.less";
.mainPage{
  box-sizing: border-box;
  padding: 20px;
  overflow: hidden;
}
.fullPage{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}
.layer{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.head{

}
.body{
  flex: 1;
  position: relative;
}
.footer{
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}
.formContainer{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  overflow: auto;
  padding: 20px 0;
}
.formContainerHide{
  .formContainer;
  opacity: 0;
  pointer-events: none;
  z-index: 1;
}