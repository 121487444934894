@import "../../common.less";

@idWidth:250px;
.mainPage{
  box-sizing: border-box;
  padding: 20px;
  overflow: visible;
}
.table{
  clear: both;
  width: 100%;
  text-align: center;
}
.tableTitle{
  font-weight: bold;
  font-size: 18px;
}
.table th,.table tr,.table td{
  text-align: center!important;
}
.idItem{
  width: @idWidth;
}
.redText{
  //color: red;
}
.greenText{
  //color: #01C12D;
}
.blueText{
  //color: #2db7f5;
}
.yellowText{
  //color: #ffd000;
}
.table .tableCountryName{
  text-align: left !important;
}
.table .tableCountryNameBtn{
  text-align: left !important;
  color: #0008ff;
  cursor: pointer;
}


.searchBox{
  text-align: left;
  margin: 15px 0;
}
.btnBox{
  text-align: right;
  margin-right: 15px;
  margin-bottom: 15px;
}
.modalStyle{
  :global{
    .ant-modal-body{
      padding:0;
    }
  }
}