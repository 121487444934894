@import "../../../common.less";

.mainPage{
  box-sizing: border-box;
  padding: 20px;
  overflow: hidden;
}
.formContainer{
  padding: 30px;
  max-width: 1200px;
}
.limitLabelBox{
  max-width: 250px;
  margin: 0 auto;
  word-break: break-all;
}