.item {
  padding: 0;
}
.itemRow{
  display: inline-block;
}

.itemTitle {
  display: inline-block;
  color: #000c17;
  font-size: 14px;
  padding: 10px;
}
.itemRight{
  display: inline-block;
  padding: 10px;
}
.text{
  word-break:break-all;
}