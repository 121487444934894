.container {
  box-sizing: border-box;
}
.show {
  display: inline-block;
}
.hidden {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
