.upfileBox {
  box-sizing: border-box;
}
.delIcon {
  position: absolute;
  width: 15px;
  height: 15px;
  top: 5px;
  right: 5px;
  background-size: 100% 100%;
  opacity: 0.6;
  cursor: pointer;
}
.delIcon:hover {
  opacity: 1;
}
.imgStyle {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  width: 75px;
  height: 75px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 4px;
  margin: 8px 8px 8px 0;
  overflow: hidden;
  cursor: pointer;
  text-align: center;
}
.imgStyleBig {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  width: 75px;
  height: 75px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 4px;
  margin: 8px 8px 8px 0;
  overflow: hidden;
  cursor: pointer;
  text-align: center;
  width: 100%;
  height: 260px;
  border: 1px solid #2db7f5;
  background-color: #f5f5f5;
}
.videoBox {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 10px auto;
}
.videoBox .videoStyle {
  width: 100%;
}
.videoBox .delIconForVideo {
  position: absolute;
  width: 15px;
  height: 15px;
  top: 5px;
  right: 5px;
  background-size: 100% 100%;
  opacity: 0.6;
  cursor: pointer;
  width: 30px;
  height: 30px;
}
.videoBox .delIconForVideo:hover {
  opacity: 1;
}
.loadingBox {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  width: 75px;
  height: 75px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 4px;
  margin: 8px 8px 8px 0;
  overflow: hidden;
  cursor: pointer;
  text-align: center;
  border: 1px dashed #dfdfdf;
  cursor: auto;
  line-height: 60px;
  font-size: 14px;
  background-size: 150% 150%;
  background-position: 50% 20%;
}
.loadingBoxBig {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  width: 75px;
  height: 75px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 4px;
  margin: 8px 8px 8px 0;
  overflow: hidden;
  cursor: pointer;
  text-align: center;
  border: 1px dashed #dfdfdf;
  cursor: auto;
  line-height: 60px;
  font-size: 14px;
  background-size: 150% 150%;
  background-position: 50% 20%;
  width: 100%;
  height: 260px;
  line-height: 150px;
  background-size: 100% 100%;
}
.addBtnBox {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  width: 75px;
  height: 75px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 4px;
  margin: 8px 8px 8px 0;
  overflow: hidden;
  cursor: pointer;
  text-align: center;
  border: 1px dashed #dfdfdf;
}
.addBtnBox .addInput {
  display: none !important;
}
.addBtnBox .inputAdd {
  display: inline-block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 99;
  position: relative;
}
.addBtnBox .addBtn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: bold;
  color: #bbbbbb;
}
.addBtnBox:hover {
  border: 1px dashed #4388f4;
}
.addBtnBox:hover .addBtn {
  color: #4388f4;
}
.addBtnBoxBig {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  width: 75px;
  height: 75px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 4px;
  margin: 8px 8px 8px 0;
  overflow: hidden;
  cursor: pointer;
  text-align: center;
  border: 1px dashed #dfdfdf;
  width: 100%;
  height: 260px;
  background-color: #f5f5f5;
}
.addBtnBoxBig .addInput {
  display: none !important;
}
.addBtnBoxBig .inputAdd {
  display: inline-block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 99;
  position: relative;
}
.addBtnBoxBig .addBtn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: bold;
  color: #bbbbbb;
}
.addBtnBoxBig:hover {
  border: 1px dashed #4388f4;
}
.addBtnBoxBig:hover .addBtn {
  color: #4388f4;
}
