.show {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
.btnBox {
  box-sizing: border-box;
  border-top: 1px solid #ddd;
  padding-top: 20px;
  text-align: right;
}
.btn {
  margin: 0 10px;
}
