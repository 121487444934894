@bodyFontSize:62.5%;
@primary-color: #1585ff;
@icon-url: '/fonts/font_zck90zmlh7hf47vi';

@font-family-no-number  : -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
@font-family            : "Microsoft YaHei","微软雅黑", @font-family-no-number;

@font-size-base: 14px;
@font-size-lg: @font-size-base + 2px;

@btn-height-lg:34px;

@menu-width: 230px;

@table-thead-bg:#384251;
@table-header-bg: transparent;
@table-row-hover-bg: transparent;
@item-hover-bg: transparent;
@table-padding-vertical:13px;
@table-bg-even:rgba(18, 27, 47, 0.28);
@table-bg-odd:rgba(19,28,47,0.1);

@input-height-base:34px;
@input-height-lg:34px;

@col-bg-color: rgba(255,255,255,.1);
@title-bg-color: rgba(19,28,47,.1);

@text_color_common: #000; // 字体三级色

//字体
@font-size-base: 14px;
@font_size_title: @font-size-base + 6px;
@font_size_lt: @font-size-base - 2px;
@font-size-lg: @font-size-base + 2px;
@font_color: #333; // 字体全色
@font_menu_color: #111; // 字体主色
@font_label_color: #3e3e3e; // 字体次色
@font_green: #20e66c;
@font_orange: #f6450f;

//背景色
@white_bg_color: #fff;
@bg_color: #1F2637;

//tabs
@tab_content_margin: 20px;
@tab_close_panel_width: 200px;
@tab_close_list_head_height: 38px;
@tab_fix_width: 100px; // 固定tabpane的宽度

//button
@btn_single_width: 200px; // button width 1
@btn_single_height: 34px;
@btn_double_margin: 20px;
@btn_double_width:140px; // button width 2
@btn_double_height: 34px;
@btn_list_width:80px; // button width 3
@btn_list_height: 34px;
@btn_margin_top: 40px;
@btn-height-lg:34px;

@menu-width: 230px;
@right_width:750px;
@right_hide_width:1118px;
@menu-collapsed-width: 64px;
@menu_collapsed_padding: 12px;
@menu_line_height: 42px;
@menu_line_height_lg: 50px;
@menu_icon_margin: 15px;
@menu_collapse_btn_height: 30px;
@menu_logo_bg_color: #002140;
@menu_bg_color: #001529; //菜单栏背景色
@menu_sub_bg_color: #000c17;

//border
@border_width: 3px;

//table
@table-thead-bg: #384251;
@table-header-bg: transparent;
@table-row-hover-bg: transparent;
@item-hover-bg: transparent; // hover over color
@table-padding-vertical: 13px;
@table-bg-even: rgba(18, 27, 47, 0.28);
@table-bg-odd: rgba(19, 28, 47, 0.1);

//pagination
@pagination_bottom: 24px;
@pagination_right: 40px;

//icon
@triangle: 4.4px;
@dotRadius: 10px;
@borderRadius: 0px;
@icon_radius: 25px;
@avatar_radius: 70px;

// top banner
@banner_height: 60px;
@banner_padding_bottom: 34px;
@layout_color: #ececec;
@layout_main_color: #fff;

//全局antd样式修改
:global{
  .ant-tabs{
    height: 100%;
  }
  .ant-tabs-content{
    height: 100%;
  }
  .ant-tabs-tabpane{
    height: 100%;
  }
}