.container{
  width: 100%;
  margin-bottom: 15px;
  overflow: visible;
  position: relative;
}
.hidden{
  display: none;
}
.hiddenDiv{
  visibility:hidden;
  height: 0;
  overflow: hidden;
}
.show {
  padding-top: 25px;
  width: 100%;
  overflow: visible;
  position: relative;
}


.inputStyle{
  width: 30%;
  font-size: 16px;
}
.inputErr{
  .inputStyle;
  & input{
    border-color: #e4393c !important;
  }
}
.btn{
  margin-left: 20px;
  width: 100px;
}
.searchBtn{
  .btn;
}
.resetBtn{
  .btn;
}
.extraSearchBtn{
  .btn;
  width: 110px;
}
.clearBtn{
  cursor: pointer;
  color: #2db7f5;
}